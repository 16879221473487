import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import ImageUpload from '@/components/ImageUploadWithProgressBar/ImageUploadWithProgressBar.vue';
import TextArea from '@/components/TextArea/TextArea.vue';
import SaveButtonArea from '@/components/SaveButtonArea/SaveButtonArea.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import StatusBadge from '@/components/Badge/StatusBadge.vue';
import DeliveryService from '@/services/API/delivery.service.js';
import TransferService from '@/services/API/transfer.service';

import socketClient from '../../../services/SOCKET';
import { sendNotify, toLineMessageTemplate } from '@/utilities';
import updateProjectStatusMixin from '@/mixin/updateProjectStatus';
import CommonConst from '@/constants/CommonConst';

import moment from 'moment';
import { mapState } from 'vuex';

const LIMIT_ERROR_CONTENTS =
	'納品できるファイル数を超えています。\n既にアップロード済みファイルを削除してください';
const SELECTIVE_CONTENT = 'クライアントに納品します';
const MAX_ITEMS = 50;
const DELIVERY_ERROR_CONTENTS = '納品物をアップロードしてください';
const UNSAVE_NUMBER = 0;
const SAVED_NUMBER = 1;
const CONFIRMED_NUMBER = 2;
const FINISH_NUMBER = 3;
const DELIVERY_SCREEN_ID = 3;

export default {
	name: 'DeliveryScreen',
	mixins: [updateProjectStatusMixin],
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	components: {
		ScreenHeader,
		ProjectDeadline,
		ImageUpload,
		TextArea,
		SaveButtonArea,
		BottomButton,
		NotificationModal,
		StatusBadge,
	},
	data() {
		return {
			middleContent: ' 様 ／ ',
			screenName: 'コンセプト仕上げ',
			screenIcon: 'delivery-icon',
			uploadedContents: [],

			// Handle progress bar percents
			progressValue: 0,

			unSaveNumber: 0,

			isDelivery: true,
			showPreviewContainer: false,
			showSaveButton: true,
			showButton: false,
			showSave: false,

			selectiveModalId: 'delivery-selective-id',
			selectiveContents: SELECTIVE_CONTENT,

			fileName: undefined,
			fileType: undefined,
			fileKey: '',
			imageComment: '',
			errorContents: '',
			uploadFileParams: {
				role: 'delivery',
				delivery_id: parseInt(this.$route.params.projectId),
			},

			buttonType: 'single-btn',
			showBottomButton: true,
			bottomButtonName: '納品する',
			showStatusBadge: false,
			badgeContent: '納品データ確認待ち',
			isShowUploadContainer: true,
			mailContent: '',
			directionLink: 'nft',
			mailTitle: '',
			expiration: '60',
			subject: '',
			isSendCreator: false,
			deliveryStatus: '',
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),
		disableSaveButton() {
			return !this.fileKey;
		},
		destinations() {
			return [this.clientInfo['email_address'], this.creatorInfo['email_address']];
		},
		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}delivery`
						: `${this.projectInfo['process_client_url']}delivery`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}delivery`
					: `${this.projectInfo['process_client_url']}delivery`,
			});
		},
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},

	watch: {
		deliveryStatus(newStatus) {
			if (newStatus === CONFIRMED_NUMBER) {
				this.$store.commit('setRedDotIndexs', [CommonConst.SCREEN_ID.DELIVERY]);
			} else {
				const redDotIndexs = this.redDotIndexs.filter(
					(screenId) => screenId !== CommonConst.SCREEN_ID.DELIVERY
				);
				this.$store.commit('setRedDotIndexs', redDotIndexs);
			}
		},
	},

	filters: {
		/**
		 * Filter file name
		 * @param {String} value image file key
		 * @returns file name
		 */
		toFileName: function (value) {
			if (!value) return '';
			return value.split('/').at(-1);
		},
	},
	methods: {
		async onDownload(event, key) {
			this.$store.commit('setIsAppProcessing', true);

			// Prevent download when click to children
			if (event.target === event.currentTarget) {
				let imageUrl = await this._getPreviewImgUrl(key, this.expiration);

				let aTag = document.createElement('a');
				aTag.href = imageUrl;
				aTag.click();

				await this._getDeliveryData(this.projectId);
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		async onRegistration() {
			this.$store.commit('setIsAppProcessing', true);

			console.log('%c Register new item', 'color:red');

			if (this.uploadedContents.length >= MAX_ITEMS) {
				this.errorContents = LIMIT_ERROR_CONTENTS;
				this.$bvModal.show('error-modal');
				this.$store.commit('setIsAppProcessing', false);
				return;
			}

			if (!this.fileKey) {
				this.errorContents = DELIVERY_ERROR_CONTENTS;
				this.$bvModal.show('error-modal');
				this.$store.commit('setIsAppProcessing', false);
				return;
			}

			if (this.fileKey) {
				// Create Delivery record
				let deliveryObj = {
					comment: this.imageComment,
					image_file_name: this.fileKey,
					project_id: this.projectId,
					update_user: this.userId,
					file_type: this.fileType,
					is_save: UNSAVE_NUMBER,
				};
				let createdId = await this._createDelivery(deliveryObj);

				if (createdId) {
					// Insert Delivery Object into uploadedContents
					deliveryObj['id'] = createdId;
					this.uploadedContents.unshift(deliveryObj);

					this._revertImageData();

					this.showPreviewContainer = true;
					this.showButton = true;
					this.showSave = true;
				}
			}

			this.$store.commit('setIsAppProcessing', false);
		},
		async onDelete(index, deletedItemId) {
			this.$store.commit('setIsAppProcessing', true);

			await this._deleteImage(deletedItemId.image_file_name);

			await this._deleteDelivery(deletedItemId.id);

			this.uploadedContents.splice(index, 1);
			if (this.uploadedContents.length === 0) {
				this.showSave = true;
				this.showPreviewContainer = false;
				this.showButton = false;
			}

			this.$store.commit('setIsAppProcessing', false);
		},

		onSendDelivery() {
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		async onSendConfirmRequire() {
			this.$store.commit('setIsAppProcessing', true);

			let updateContents = [];
			this.uploadedContents.forEach((item) => {
				if (item['is_save'] === UNSAVE_NUMBER) {
					let updateContent = {
						id: item['id'],
						is_save: SAVED_NUMBER,
					};
					updateContents.push(updateContent);
				}
			});
			await this._updateBulkDeliveries(updateContents);

			this.showStatusBadge = true;
			this.deliveryStatus = SAVED_NUMBER;

			// Send event
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'delivery_content',
				},
				room: this.projectId,
			});

			await this._getDeliveryData(this.projectId);

			// send mail to creator case 53
			// this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］納品データを送信しました`;
			// this.mailContent = `納品データを\n送信しました。`;
			// this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 納品データを送信しました`;
			// this.isSendCreator = true;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['responsible_user'],
			// ]);
			// this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 納品データが届きました`;
			// this.mailContent = `納品データが\n届きました。`;
			// this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 納品データが届きました`;
			// this.isSendCreator = false;
			// sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
			// 	this.projectInfo['client_id'],
			// ]);

			this.$store.commit('setIsAppProcessing', false);
		},

		onInputRemark(data) {
			this.imageComment = data.comment;
		},

		async onDirectionButtonClick() {
			this.$store.commit('setIsAppProcessing', true);

			let updateContents = [];
			this.uploadedContents.forEach((item) => {
				let updateContent = {
					id: item['id'],
					is_save: FINISH_NUMBER,
				};
				updateContents.push(updateContent);
			});
			await this._updateBulkDeliveries(updateContents);
			await this.updateProjectStatus(this.projectId, DELIVERY_SCREEN_ID + 1);

			this.deliveryStatus = FINISH_NUMBER;
			this.showButton = false;
			this.$emit('on-send-confirm-info', {
				screenId: DELIVERY_SCREEN_ID,
				confirmInfo: true,
			});

			this.$store.commit('setIsAppProcessing', false);
			this.$router.push(this.directionLink);
		},

		async onUploadImage(data) {
			this.fileName = data.name;
			this.fileType = data.type;
			this.fileKey = data.fileKey;
		},

		/**
		 * Revert uploading component data to empty
		 */
		_revertImageData() {
			this.fileName = '';
			this.imageComment = '';
			this.fileKey = '';
			this.$refs.imageUpload._revertData();
			this.$refs.textArea._revertData();
		},

		onRemoveImageData() {
			this.fileName = '';
			this.fileKey = '';
		},

		/**
		 * Create new Delivery record
		 * @param {Object} params Delivery object
		 * @returns Created record ID
		 */
		async _createDelivery(params) {
			try {
				let response = await DeliveryService.post({
					doc_content: params,
				});

				if (!response || response.status !== 200) {
					throw 'Create Delivery failed';
				}

				console.log('%c Create Delivery successfully', 'color: red');
				return response.data['id'];
			} catch (error) {
				console.log(`Create Delivery failed: ${error}`);
			}
		},

		/**
		 * Get DeliveryData
		 * @param {Integer} projectId Project ID
		 * @returns
		 */
		async _getDeliveryData(projectId) {
			try {
				let response = await DeliveryService.get(projectId);
				if (!response || response.status !== 200) {
					throw 'Get Delivery failed!';
				}

				if (response.data.length > 0) {
					this.showPreviewContainer = true;
					this.showSave = true;
				}
				// reverse array
				// Newest item is the first item
				let uploadedContents = [...response.data];
				this.uploadedContents = uploadedContents.reverse();
				if (this.uploadedContents && this.uploadedContents.length > 0) {
					this.showButton = true;
				}

				let lastItem = this.uploadedContents[this.uploadedContents.length - 1];
				this.deliveryStatus = lastItem['is_save'];

				if (lastItem && lastItem['is_save'] === SAVED_NUMBER) {
					this.showStatusBadge = true;
				}

				if (lastItem && lastItem['is_save'] === CONFIRMED_NUMBER) {
					this.showStatusBadge = false;
					this.isShowUploadContainer = false;
					this.buttonType = 'direct-screen-btn';
					this.bottomButtonName = 'デジタル認証に進む';
				}

				if (lastItem && lastItem['is_save'] === FINISH_NUMBER) {
					this.showStatusBadge = false;
					this.isShowUploadContainer = false;
					this.showButton = false;
				}

				console.log('%c Get Delivery successfully!', 'color: green');
			} catch (error) {
				console.log(error);
			}
		},
		/**
		 * Delete Delivery record
		 * @param {Integer} deliveryId Delivery ID
		 * @returns
		 */
		async _deleteDelivery(deliveryId) {
			try {
				let response = await DeliveryService.delete(deliveryId);

				if (!response || response.status !== 200) {
					throw 'Delete Delivery failed';
				}

				console.log('%c Delete Delivery successfully', 'color: red');
			} catch (error) {
				console.log(`Delete Delivery failed: ${error}`);
			}
		},

		/**
		 * Delete image by image key
		 * @param {String} imgKey Image key
		 */
		async _deleteImage(imgKey) {
			try {
				let response = await TransferService.delete(imgKey);
				if (!response || response.status !== 200) {
					throw 'Delete image failed!';
				}

				console.log('%c Delete image successfully!', 'color: red');
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Update bulk contents
		 * @param {Array} contents update contents
		 */
		async _updateBulkDeliveries(contents) {
			let response = await DeliveryService.updateBulk(contents);
			if (response && response.status === 200) {
				console.log('%c Update Bulk Delivery successfully!', 'color: green');
			} else {
				throw response.message;
			}
		},

		/**
		 * Get image preview url
		 * @param {String} imgKey Image key to get image url
		 * @param {String} expiration The lifetime of the url in seconds
		 * @returns image url
		 */
		async _getPreviewImgUrl(imgKey, expiration) {
			try {
				let response = await TransferService.get(imgKey, expiration);
				if (!response || response.status !== 200) {
					throw 'Get image url failed!';
				}

				console.log('%c Get image url successfully!', 'color: red');
				let imgUrl = response.data.link;
				return imgUrl;
			} catch (error) {
				console.log(error);
			}
		},
	},

	async created() {
		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'delivery_confirm':
					this.$store.commit('setIsAppProcessing', true);

					await this._getDeliveryData(this.projectId);

					this.$store.commit('setIsAppProcessing', false);
					break;

				default:
					break;
			}
		});
	},

	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		moment.locale('ja');
		await this._getDeliveryData(this.projectId);

		this.$store.commit('setIsAppProcessing', false);
	},
};
